<template>
    <div class="container page">
      <div class="header">
        <van-nav-bar :title="$t('my.point')" class="nav-bar">
          <template #left>
            <van-icon name="arrow-left" color="#fff" @click="back()" />
          </template>
  
          <template #right>
            <!-- <span class="nav-right" @click="$router.push({path:'/RechargeRecord'})">{{$t("recharge.with_record")}}</span> -->
          </template>
        </van-nav-bar>
        <div class="info">
          <p class="title">
            {{ $t("my.point_lab") }}
          </p>
          <p class="value">{{ point }}</p>
        </div>
       
      </div>
    </div>
  </template>
  
  <script>
  import Vue from "vue";
  import { Form } from "vant";
  import { Field } from "vant";
  import { RadioGroup, Radio } from "vant";
  Vue.use(Form).use(Field).use(RadioGroup).use(Radio);
  export default {
    data() {
      return {
        point:0
      };
    },
    mounted() {
      this.getUserInfo()
    },
    methods: {
        getUserInfo() {
        this.$http({
            method: 'get',
            url: 'user_info'
        }).then(res => {
            if (res.code === 200) {
              console.log(res.data,888)
            this.point = res.data.point;
            // if (this.userInfo.status !== 1) {
            //     this.$toast(this.$t("video.account_out"));
            //     localStorage.clear()
            //     this.$router.push({ path: '/Login' })
            // }
            } else if (res.code === 401) {
            this.$toast(res.msg);
            }
        })
        },
      back() {
        return window.history.back();
      },
  
      getPersonalreport() {
        this.$http({
          method: "get",
          url: "user_get_personalreport",
        }).then((res) => {
          if (res.code === 200) {
            this.personalreport = res.data;
            this.win_money =
              this.personalreport.win_money - this.personalreport.play_money;
          } else if (res.code === 401) {
            this.$toast(res.msg);
          }
        });
      },
    },
    created() {
      if (!localStorage.getItem("token")) {
        this.$router.push({ path: "/Login" });
      } else {
        this.getPersonalreport();
      }
    },
  };
  </script>
  
    <style lang='less' scoped>
  @import "../../assets/css/base.css";
  .container .header {
    background: linear-gradient(270deg, #c24491, #775fd9);
  }
  .recharge {
    padding: 10px 30px;
  }
  
  .van-cell {
    line-height: 65px !important;
  }
  
  .van-button {
    height: 87px !important;
  }
  .van-button__text {
    color: #fff !important;
  }
  
  /deep/.van-radio__icon {
    font-size: 30px !important;
  }
  /deep/.van-radio__label {
    margin-left: 25px !important;
    font-size: 35px !important;
  }
  
  /deep/.van-radio {
    height: 65px !important;
  }
  
  .form-item {
    margin-top: 40px;
  }
  
  .form-item-title {
    font-size: 36px;
    font-weight: bold;
    color: #999;
    margin-bottom: 20px;
  }
  
  .recharge span {
    font-size: 4vw;
    color: #868686;
    font-weight: 500;
  }
  
  .container .header .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 10px;
    margin: auto;
  }
  .container .header .info .title {
    font-size: 25px;
    color: #e5e5e5;
  }
  .container .header .info .value {
    margin: 10px auto;
    color: #fff;
    font-size: 50px;
    border-bottom: 1px solid #fff;
  }
  .container .header .info .tip {
    font-size: 30px;
    color: #e5e5e5;
  }
  .container .content {
    flex: 1;
    background: #f2f2f5;
  }
  .subBtn{
    background: #775fd9;
  }
  </style>
  